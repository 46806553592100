<template>
  <el-button size="small" type="primary" @click="addBus"  >
		<el-icon><CirclePlus /></el-icon><span  >{{ name}}</span></el-button>
  <el-dialog :close-on-click-modal="false" destroy-on-close :title="name" v-model="dialogFormVisible" width="20%" append-to-body>
    <el-form :model="form">
			<el-form-item label="编码" :label-width="formLabelWidth">
				<el-input v-model="form.code" autocomplete="off" size="small" style="width:90%"></el-input>
			</el-form-item>
			<el-form-item label="名称" :label-width="formLabelWidth">
				<el-input v-model="form.name" autocomplete="off" size="small" style="width:90%"></el-input>
			</el-form-item>
    </el-form>
    <template #footer>
			<span class="dialog-footer">
				<el-button size="small" plain type="primary" @click="dialogFormVisible = false">取 消</el-button>
				<el-button size="small" plain type="primary" @click="addBussiness">确 定</el-button>
			</span>
    </template>
</el-dialog>
</template>

<script>
import { fzhsItemSave , newFzhsCode } from "@/api/subject"
export default {
	props: {
		id: 0,
		name:{
			type:String,
			default:'编辑'
		},
		scopeRow:{
      type:Array,
      default:[]
    }
	},
	data() {
		return {
			form: {
				name: '',
				code: '',
				remark: '',
			},
			dialogFormVisible:false,
		}
	},
	created(){
    this.init()
	},
	methods:{
		init(){
			console.log(this.scopeRow);
		},
		addBus(){
			this.form.name = ''
      this.dialogFormVisible = true
			if(this.name == '编辑'){
        this.form = this.scopeRow
			}else{
				newFzhsCode({fzhsId:this.id}).then(res=>{
        this.form.code = res.data.data.code
      })
			}
		},
		addBussiness(){
			if(this.id == 0){
				return
			}
			this.form.fzhsId = this.id
			fzhsItemSave([this.form]).then(res=>{
				if(res.data.msg == "success"){
					this.dialogFormVisible = false;
					this.$qzfMessage("设置成功") 
          this.$store.dispatch("commons/getSubjects")
					this.$store.dispatch("commons/getBusinessType")
					this.$emit('success')
				}
			})
		}
	}
}
</script>

<style>

</style>